import { Card, Grid } from "@mui/material";
import React from 'react'
import ContactFormComponent from "../ContactForm/ContactFormComponent";

const MapsComponent = () => {
  return (
    <>
      <Grid container style={{ marginBottom: 100 }}>
        <Grid item md={4} sm={12} sx={{ pl: { sx: 0, md: 5, width: "100%" } }}>
          <Card
            sx={{
              pb: { sx: 0, md: 1 },
              m: 0,
            }}
          >
            <ContactFormComponent />
          </Card>
        </Grid>
        <Grid
          item
          md={8}
          sm={12}
          xs={12}
          sx={{
            px: { sx: 0, md: 4 },
            pr: { sx: 0, md: 5 },
            py: { sx: 6, md: 2 },
            width: "100%",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.617923035992!2d-46.543846099999996!3d-23.5821621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5dbaeaeebabb%3A0x523c7cbe7d874fed!2sRua%20Capit%C3%A3o%20Venceslau%20Japiassu%20Arag%C3%A3o%2C%20120%20-%20Vila%20Ema%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003278-070!5e0!3m2!1sen!2sbr!4v1725486418549!5m2!1sen!2sbr"
            width="100%"
            height="100%"
            style={{ border: 0, height: "100%", borderRadius: 10 }}
          ></iframe>
        </Grid>
      </Grid>
    </>
  );
};

export default MapsComponent;
