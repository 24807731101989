import React, { useState } from 'react';
import { Box, Grid, Typography, styled, Modal } from '@mui/material';
import TitlePageComponent from '../Titles/TitlePageComponent';
import Image1 from "../../assets/galeria/intro3.jpeg";
import Image2 from "../../assets/galeria/intro7.jpeg";
import Image3 from "../../assets/corrimao1.jpeg";
import Image4 from "../../assets/galeria/intro8.jpg";
import Image5 from "../../assets/galeria/intro9.jpeg";
import Image6 from "../../assets/galeria/intro10.jpeg";
import GalleryImage1 from "../../assets/corrimao2/foto1.jpeg";
import GalleryImage2 from "../../assets/corrimao2/foto2.jpeg";
import GalleryImage3 from "../../assets/corrimao2/foto3.jpeg";
import GalleryImage4 from "../../assets/corrimao2/foto4.jpeg";
import GalleryImage5 from "../../assets/corrimao2/foto5.jpeg";
import GalleryImage6 from "../../assets/corrimao1/foto1.jpeg";
import GalleryImage7 from "../../assets/corrimao1/foto2.jpeg";
import GalleryImage8 from "../../assets/corrimao1/foto3.jpeg";
import GalleryImage9 from "../../assets/corrimao1/foto4.jpeg";
import GalleryImage10 from "../../assets/corrimao3/foto5.jpeg";
import GalleryImage11 from "../../assets/corrimao3/foto1.jpeg";
import GalleryImage12 from "../../assets/corrimao3/foto2.jpeg";
import GalleryImage13 from "../../assets/corrimao3/foto3.jpeg";
import GalleryImage14 from "../../assets/corrimao3/foto4.jpeg";
import GalleryImage15 from "../../assets/corrimao3/foto5.jpeg";
import GalleryImage16 from "../../assets/corrimao4/foto1.jpeg";
import GalleryImage17 from "../../assets/corrimao4/foto2.jpeg";
import GalleryImage18 from "../../assets/corrimao4/foto3.jpeg";
import GalleryImage19 from "../../assets/corrimao4/foto4.jpeg";
import GalleryImage20 from "../../assets/corrimao4/foto5.jpeg";
import GalleryImage21 from "../../assets/corrimao5/foto6.jpeg";
import GalleryImage22 from "../../assets/corrimao5/foto1.jpeg";
import GalleryImage23 from "../../assets/corrimao5/foto2.jpeg";
import GalleryImage24 from "../../assets/corrimao5/foto3.jpeg";
import GalleryImage25 from "../../assets/corrimao5/foto4.jpeg";
import GalleryImage26 from "../../assets/corrimao5/foto5.jpeg";
import GalleryImage27 from "../../assets/corrimao6/foto1.jpeg";
import GalleryImage28 from "../../assets/corrimao6/foto2.jpeg";
import GalleryImage29 from "../../assets/corrimao6/foto3.jpeg";
import GalleryImage30 from "../../assets/corrimao6/foto4.jpeg";
import GalleryImage32 from "../../assets/corrimao6/foto5.jpeg";








import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const services = [
  {
    title: "Guarda corpo 2 polegadas com barras de 5/8 em alumínio branco/preto",
    image: Image1,
    gallery: [
      { original: GalleryImage1, thumbnail: GalleryImage1 },
      { original: GalleryImage2, thumbnail: GalleryImage2 },
      { original: GalleryImage3, thumbnail: GalleryImage3 },
      { original: GalleryImage4, thumbnail: GalleryImage4 },
      { original: GalleryImage5, thumbnail: GalleryImage5 },

    ],
  },
  {
    title: "Corrimão de 2 polegadas, com barra de 1 polegada em alumínio ou inox, preto ou branco",
    image: Image2,
    gallery: [
      { original: GalleryImage6, thumbnail: GalleryImage6 },
      { original: GalleryImage7, thumbnail: GalleryImage7 },
      { original: GalleryImage8, thumbnail: GalleryImage8 },
      { original: GalleryImage9, thumbnail: GalleryImage9 },
      { original: GalleryImage10, thumbnail: GalleryImage10 },

    ],
  },
  {
    title: "Guarda corpo 2 polegadas em alumínio branco/preto/inox com vidro 8mm verde/incolor/fumê",
    image: Image3,
    gallery: [
      { original: GalleryImage11, thumbnail: GalleryImage11 },
      { original: GalleryImage12, thumbnail: GalleryImage12 },
      { original: GalleryImage13, thumbnail: GalleryImage13 },
      { original: GalleryImage14, thumbnail: GalleryImage14 },
      { original: GalleryImage15, thumbnail: GalleryImage15 },

    ],
  },
  {
    title: "Corrimão de parede 2 polegadas alumínio branco/preto/inox",
    image: Image4,
    gallery: [
      { original: GalleryImage16, thumbnail: GalleryImage16 },
      { original: GalleryImage17, thumbnail: GalleryImage17 },
      { original: GalleryImage18, thumbnail: GalleryImage18 },
      { original: GalleryImage19, thumbnail: GalleryImage19 },
      { original: GalleryImage20, thumbnail: GalleryImage20 },

    ],
  },
  {
    title: "Corrimão de parede 1.1/2 alumínio branco/preto/inox simples e duplo",
    image: Image5,
    gallery: [
      { original: GalleryImage21, thumbnail: GalleryImage21 },
      { original: GalleryImage22, thumbnail: GalleryImage22 },
      { original: GalleryImage23, thumbnail: GalleryImage23 },
      { original: GalleryImage24, thumbnail: GalleryImage24 },
      { original: GalleryImage25, thumbnail: GalleryImage25 },
      { original: GalleryImage26, thumbnail: GalleryImage26 },

    ],
  },
  {
    title: "Corrimão de parede chato alumínio branco/preto",
    image: Image6,
    gallery: [
      { original: GalleryImage27, thumbnail: GalleryImage27 },
      { original: GalleryImage28, thumbnail: GalleryImage28 },
      { original: GalleryImage29, thumbnail: GalleryImage29 },
      { original: GalleryImage30, thumbnail: GalleryImage30 },
      { original: GalleryImage32, thumbnail: GalleryImage32 },

    ],
  },
];

const ServiceCard = styled(Box)(({ theme }) => ({
  border: '1px solid #ddd',
  borderRadius: 8,
  padding: 16,
  margin: 16,
  background: 'linear-gradient(135deg, #ebebeb 0%, #989898 100%)',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  }
}));

const ServiceTitle = styled(Typography)({
  fontSize: 24,
  fontWeight: 'bold',
  marginBottom: 8
});

const ServiceImage = styled('img')({
  width: '100%',
  height: 'auto',
  borderRadius: 8,
  cursor: 'pointer',
  marginBottom: 8
});

const ServicesComponent = () => {
  const [open, setOpen] = useState(false);
  const [selectedGallery, setSelectedGallery] = useState([]);

  const handleOpen = (gallery) => {
    setSelectedGallery(gallery);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <TitlePageComponent title="Nossos Produtos" />
      <Grid container spacing={2}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ServiceCard>
              <ServiceImage
                src={service.image}
                alt={service.title}
                onClick={() => handleOpen(service.gallery)}
              />
              <ServiceTitle>{service.title}</ServiceTitle>
            </ServiceCard>
          </Grid>
        ))}
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ maxWidth: '80%', maxHeight: '80%', margin: 'auto', marginTop: '5%', outline: 'none' }}>
          <ImageGallery items={selectedGallery} showThumbnails={true} />
        </Box>
      </Modal>
    </Box>
  );
};

export default ServicesComponent;
